
::v-deep .el-tabs__header{
   margin: 0;
}
::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
   border: none;
}
::v-deep  .is-active{
    background-color: white;
}
.rank-content{
        display: flex;
        height: 100%;
        /*width: calc(100% - 280px);*/
        /*padding: 20px 20px 0 0;*/
        flex-direction: column;
        ::v-deep.el-tabs__header .is-active {
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            color: #0824ce;
            border-radius: 6px 6px 0 0;
        }
        ::v-deep.el-tabs__item{
            height: 52px;
            line-height: 52px;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
            border: none;
        }
        ::v-deep.el-tab-pane{
            height: 100%;
        }
        ::v-deep.el-tabs{
            height: 100%;
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
        }
        ::v-deep.el-tabs__header{
            border-bottom: none;
            margin: 0;
        }
        ::v-deep.el-tabs__content{
            height: 1%;
            flex: 1;
            background: #fff;
            padding-top: 20px;
        }
    }
